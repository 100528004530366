html,
body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* the slides */
.slick-slide {
  padding: 0 15px 0 3px;
  outline: 0 !important;
}

.ReactModal__Overlay {
  z-index: 14;
  /* background-color: transparent !important; */
  transition: opacity 250ms ease-in-out;
  opacity: 0;
  /* animation: slide-in 250ms forwards;
    -webkit-animation: slide-in 250ms forwards; */
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  /* animation: slide-out 250ms forwards;
    -webkit-animation: slide-out 250ms forwards; */
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  /* animation: slide-in 250ms forwards;
    -webkit-animation: slide-in 250ms forwards; */
}

.ReactModal__Content--after-open {
  /* opacity: 1; */
  animation: slide-out 250ms forwards;
  -webkit-animation: slide-out 250ms forwards;
}

.ReactModal__Content--before-close {
  /* opacity: 0; */
  animation: slide-in 250ms forwards;
  -webkit-animation: slide-in 250ms forwards;
}

@media only screen and (max-width: 750px) {
  .kampp-modal {
    width: 90% !important;
    height: 97% !important;
    top: 20px !important;
  }
}

@keyframes slide-in {
  100% {
    transform: translateY(25%);
  }
}

@-webkit-keyframes slide-in {
  100% {
    transform: translateY(25%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateY(10%);
  }
  100% {
    transform: translateY(0%);
  }
}

@-webkit-keyframes slide-out {
  0% {
    transform: translateY(10%);
  }
  100% {
    transform: translateY(0%);
  }
}
